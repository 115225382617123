import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import {Header} from "../../components/Header";
import {ContainerStyle} from "../../styles/ContainerStyle";
import {Spacer} from "../../components/Spacer";
import {Redirect} from "react-router-dom";
import {EventService} from "../../services/EventsService";
import {AuthService} from "../../services/AuthService";
import {Loading} from "../../components/Loading";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventCode: '',
      eventPass: '',
      addonGuid: '',
      isLogged: false,
      loading: false
    }
  };

  handleEventCodeChange = async (event) => {
    this.setState({eventCode: event.target.value});
  };

  handleEventPassChange = async (event) => {
    this.setState({eventPass: event.target.value});
  };

  render() {
    return (
      <div style={ContainerStyle.container}>
        {this.renderRedirect()}
        <Header/>
        <div>
          <Grid container
                spacing={3}>
            <Grid item
                  xs={12}
                  sm={6}>
              <form>
                <h4 style={{textAlign: "center"}}>Conheça seu estilo comportamental</h4>
                <TextField fullWidth
                           id="eventCode"
                           label="Evento"
                           margin="normal"
                           variant="outlined"
                           value={this.state.eventCode}
                           onChange={this.handleEventCodeChange}/>
                <TextField fullWidth
                           id="eventPass"
                           label="Senha"
                           margin="normal"
                           variant="outlined"
                           value={this.state.eventPass}
                           type={'password'}
                           onChange={this.handleEventPassChange}/>
                <Spacer/>
                <Button
                  size={"large"}
                  fullWidth
                  type="button"
                  color="inherit"
                  variant="outlined"
                  onClick={this.onClickLogin}>
                  Acessar evento
                </Button>
              </form>
            </Grid>
            <Grid item
                  xs={12}
                  sm={6}>
              <div>
                <p style={{textIndent: "20px"}}>
                  Agora a Special Dog, através da parceria com a Thomas International, maior provedora global de
                  avaliações psicométricas, disponibiliza para você a tecnologia mais avançada existente no mercado em
                  termos de instrumento gerencial voltado ao processo de autoconhecimento, visando a identificação de
                  seus melhores talentos para maximizar o plano de carreira.
                </p>
                <p style={{textIndent: "20px"}}>
                  Além disso, poderemos juntos dar continuidade a esse processo de desenvolvimento profissional ao longo
                  de 2020, interpretando esses resultados e construindo um plano de ação.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
        <ToastContainer/>
        <Loading hide={this.state.loading}/>
      </div>
    );
  }

  onClickLogin = async (event) => {
    event.preventDefault();
    this.setState({loading: true});
    let access = await AuthService.login(this.state.eventCode, this.state.eventPass);
    if (access) {
      let addonGuid = await EventService.getAddonGuid(this.state.eventCode, this.state.eventPass);
      this.setState({addonGuid: addonGuid});
      this.setState({isLogged: true});
    } else {
      this.toastError('Código ou senha inválido');
    }
    this.setState({loading: false});
  };

  toastError = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  };

  renderRedirect = () => {
    if (this.state.isLogged) {
      let to = '/assessment/request/' + this.state.addonGuid;
      return <Redirect to={to}/>
    }
  };
}


