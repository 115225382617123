import axios from 'axios';
import ServiceSetting from '../settings/ServiceSetting'
import {AccessStorage} from "../storages/AccessStorage";

export class AssessmentService {
  static requestAsessment = async (addonGuid, firstName, lastName, phone, email, cpf, externalValidation) => {
    try {
      let access = AccessStorage.get();
      let user = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        cpf: cpf,
        externalValidation: externalValidation
      };
      let resp = await axios.post(
        `${ServiceSetting.addonUrl}v1/assessment/request/${addonGuid}`, user,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

}
